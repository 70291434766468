<template>
  <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="email">Email</label>
        <p id="email">{{data.email}}</p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="status">Status</label>
        <p id="status">{{data.status}}</p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="firstName">First Name</label>
        <p id="firstName">{{data.firstName?data.firstName:"N/A"}}</p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="lastName">Last Name</label>
        <p id="lastName">{{data.lastName?data.lastName:"N/A"}}</p>
      </div>
    </vs-col>

    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="contactNumber">Phone Number</label>
        <p id="contactNumber">{{data.contactNumber?data.contactNumber:"N/A"}}</p>
      </div>
    </vs-col>
  </vs-row>
</template>


<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>