<template>
  <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="allowCall">Call</label>
        <p id="allowCall">{{data.allowCall?"Yes":"No"}}</p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="allowSms">SMS</label>
        <p id="allowSms">{{data.allowSms?"Yes":"No"}}</p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="contactName">Contact Name</label>
        <p id="contactName">{{data.contactName?data.contactName:"N/A"}}</p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="contactNumber">Contact Number</label>
        <p id="contactNumber">{{data.contactNumber?data.contactNumber:"N/A"}}</p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="email">Email</label>
        <p id="email">{{data.email?data.email:"N/A"}}</p>
      </div>
    </vs-col>
    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
      <div class="w-full m-5">
        <label for="mailingAddress">Mailing Address</label>
        <p id="mailingAddress">{{data.mailingAddress?data.mailingAddress:"N/A"}}</p>
      </div>
    </vs-col>
  </vs-row>
</template>


<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>