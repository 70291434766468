<template>
  <vx-card slot="no-body" class="tabs-container px-6 pt-6">
    <vs-tabs v-model="activeTab" class="tab-action-btn-fill-conatiner">
      <vs-tab label="Basic" icon-pack="feather" icon="icon-flag">
        <!-- <div class="tab-text"> -->
        <basic-view class="mt-4" :data="userDetail"></basic-view>
        <!-- </div> -->
      </vs-tab>
      <vs-tab label="Contact Information" icon-pack="feather" icon="icon-user">
        <div class="tab-text">
          <contact-view class="mt-4" :data="userDetail.contactInformation"></contact-view>
        </div>
      </vs-tab>
      <vs-tab label="Subscriptions" icon-pack="feather" icon="">
        <div class="tab-text">
          <subscription-view class="mt-4" :userId="userDetail._id"></subscription-view>
        </div>
      </vs-tab>
    </vs-tabs>
  </vx-card>
  <!-- <vs-row vs-justify="flex-end">
      <vs-button
        color="danger"
        style="margin-left:5px;"
        @click="$router.replace('/admin/user-management')"
      >Back</vs-button>
  </vs-row>-->
</template>

<script>
  import { mapActions } from "vuex";
  import moment from "moment";
  import BasicView from "./basicView.vue";
  import ContactView from "./contactView.vue";
  import SubscriptionView from "./mySubscription.vue";
  export default {
    name: "UserDetails",
    components: {
      BasicView,
      ContactView,
      SubscriptionView
    },
    data: () => ({
      userDetail: {},
      activeTab: 0,
    }),
    methods: {
      ...mapActions("user", ["fetchUser"]),
      getUserDetail(id) {
        let self = this;
        this.fetchUser(id).then((res) => {
          this.userDetail = res.data.data;
        });
      },
    },
    created() {
      let userId = this.$route.params.id;
      this.getUserDetail(userId);
    },
  };
</script>
<style>
  .ql-editor {
    min-height: 200px;
  }
</style>
